<template>
  <el-row :gutter="20" style="margin: 0 !important;overflow-y: auto;">
    <div v-loading="loading.listLoading"></div>
    <el-empty v-if="carList.length === 0 && !loading.listLoading" class="py-80" description="请选择认证类型（两种选其一）">
      <el-button type="warning" size="small" icon="el-icon-truck" @click="goItemPage('carCertification', 'noparams')">车辆认证</el-button>
      <el-button type="success" size="small" icon="el-icon-ship" @click="goItemPage('transitionCertification', 'noparams')">过渡认证</el-button>
    </el-empty>
    <el-col
        v-else
        :span="6"
        class="mb-20"
        v-for="car in carList">
      <el-card class="box-card">
        <div class="mask">
          <el-button v-if="car.sign !== '1'" type="primary" icon="el-icon-edit" circle @click="handleEdit(car)"></el-button>
          <el-button type="danger" icon="el-icon-delete" circle @click="handleDel(car)"></el-button>
        </div>
        <div slot="header" class="clearfix">
          <span>
            <img v-if="car.type==='1'" src="@/assets/img/boat.png" height="20" alt=""
                 class="mr-5"
                 style="position: relative;top: 4px;">
            <img v-else src="@/assets/img/car.png" height="20" alt=""
                 class="mr-5"
                 style="position: relative;top: 4px;">
            {{ car.mvlicense }}
          </span>
          <el-tag v-if="car.sign === '0'" class="fr" size="medium" type="warning">审核中</el-tag>
          <el-tag v-if="car.sign === '1'" class="fr" size="medium" type="success">已审核</el-tag>
          <el-tag v-if="car.sign === '2'" class="fr" size="medium" type="danger">审核失败</el-tag>
        </div>
        <ul>
          <li>
            <i class="tc-link mr-5 el-icon-menu"></i>
            认证类型：{{ car.type === '1' ? '过渡认证' : '车辆认证' }}
          </li>
          <li>
            <i class="tc-link mr-5 el-icon-time"></i>
            认证时间：{{ car.inputtime }}
          </li>
        </ul>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import publicFun from "@/mixins/public";
import interfaceWechat from "@/mixins/interfaceWechat";

export default {
  name: "carInfo",
  mixins: [publicFun, interfaceWechat],
  data() {
    return {
      searchParams: {
        unionid: '',
        mvlicense: '',
        sign: '0'
      },
      loading: {
        listLoading: false
      },
      carList: []
    }
  },
  mounted() {
    this.searchParams.unionid = sessionStorage.userinfo ? JSON.parse(sessionStorage.userinfo).unionid : '';
    if (this.searchParams.unionid) this.getList();
  },
  methods: {
    getList() {
      this.getInfo('/carownerinfo/list', this.searchParams, 'carList', 'loading', this.setFlag);
    },
    setFlag() {
      this.$parent.hasData = this.carList.length > 0;
    },
    goItemPage(name, item) {
      if (this.goLogin()) {
        this.$router.push({
          name: name,
          params: {
            item: JSON.stringify(item)
          }
        });
      }
    },
    handleEdit(item) {
      if (item.type === '0') {
        let params = {
          mvlicense: item.mvlicense,
          drivinglicense: item.drivinglicense
        }
        this.goItemPage('carCertification', params);
      } else {
        this.goItemPage('transitionCertification', item);
      }
    },
    handleDel(item) {
      let params = {
        unionid: item.unionid,
        mvlicense: item.mvlicense
      }
      this.deleteInfo('/carownerinfo/delete', params, this.getList);
    }
  }
}
</script>

<style scoped lang="scss">
ul {
  li {
    padding: 5px 0;
  }
}
.box-card {
  overflow: hidden;
  ::v-deep {
    .el-card__header {
      padding: 12px 20px;
    }
    .el-card__body {
      position: relative;
      .mask {
        display: none;
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        text-align: center;
        line-height: 100px;
      }
      &:hover {
        .mask {
          display: block;
        }
      }
    }
  }
}
</style>